@import url("./work-sans.css");

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @media print {
    #header {
      display: none !important;
    }
    #account-sidebar {
      display: none !important;
    }
    #FooterWrapper {
      display: none !important;
    }
  }

  html {
    font-family: Work Sans;
    @apply antialiased;
    @apply scroll-pt-[152px] lg:scroll-pt-[120px]; /* Applied so #main anchor links will scroll past the Fixed Header */

    /* Theme Token Fallback colors for Hero Illustration since SVG <path>'s can't use classNames and we couldn't use fill="currentColor" */
    --text-color-hero-illustration-1: 220, 38, 38;
    --text-color-hero-illustration-2: 226, 74, 74;
    --text-color-hero-illustration-3: 183, 32, 32;
  }
  a:focus,
  button:focus,
  input:focus {
    border-radius: 0.25rem /* 4px */;
  }
  *:disabled {
    cursor: not-allowed;
  }
  body.inactive {
    overflow: hidden; /* Remove background scrolling when modal is open */
  }
}

.skip-nav-link {
  clip: rect(1px, 1px, 1px, 1px);
  display: block;
  height: 1px;
  overflow: hidden;
  padding: 20px;
  position: absolute;
  text-decoration: underline;
  top: 1rem;
  left: 1rem;
  width: 1px;
  z-index: 999;
}

.skip-nav-link:focus {
  clip: auto;
  height: auto;
  overflow: visible;
  width: auto;
  position: static;
}

[data-reach-slider-track][data-orientation="horizontal"] {
  background: hsla(0, 0%, 50%, 0.3);
}

.remove-child-focus-ring input:focus {
  @apply ring-0 ring-offset-0;
}

/* Copied over from ClarkChains */
.payment-form__container iframe {
  width: 100%;
  max-width: 600px;
  border-style: none;
  height: 100px;
}

@layer components {
  .video-control--primary {
    @apply flex flex-1 cursor-pointer flex-col items-center justify-center border-0 bg-transparent text-white opacity-50 transition-opacity duration-150 ease-in-out hover:opacity-100;
  }

  .product-card {
    @apply flex flex-col justify-between p-6;
  }

  .category-card {
    @apply rounded-lg;
  }

  .lot-quantity-pill-bubble {
    @apply h-5 w-fit px-1 rounded-full bg-gray-500 text-white font-medium text-sm flex items-center justify-center;
  }

  /* Use on the first component below the Fixed Header */
  .header-top-spacing {
    @apply pt-[152px] lg:pt-[120px];
  }

  /* Use on a sticky component so it sticks below the Fixed Header */
  .header-top-offset {
    @apply top-[152px] lg:top-[120px];
  }

  .login-bg-gradient {
    @apply bg-gradient-to-b from-[#086E77] to-[#0F2C41];
  }

  .login-form-wrapper {
    @apply px-6 py-4 xs:px-16 xs:py-10 flex flex-col gap-12 w-full lg:w-3/4 xl:w-full m-auto;
  }

  .expanded-description__wrapper {
    @apply flex flex-col items-center border rounded-lg w-full my-16;
  }

  .expanded-description__section {
    @apply px-8 py-16 w-full border-b last:border-b-0;
  }

  .expanded-description__image {
    @apply rounded-lg w-[320px];
  }

  .expanded-description__image--large {
    @apply border rounded-lg w-[400px];
  }

  .expanded-description__paragraph {
    @apply lg:max-w-[800px];
  }

  .marketplace-button {
    @apply outline-none text-center font-medium rounded focus:ring-2 focus:ring-blue-400 focus:ring-offset-2 flex place-items-center justify-center;
  }

  .button-small {
    @apply h-8 p-1 xs:p-2 text-xs space-x-1;
  }

  .button-group {
    @apply h-9 p-3 xs:p-4 text-sm space-x-2;
  }

  .button-default {
    @apply h-10 p-3 xs:p-4 text-sm space-x-2;
  }

  .size-6x6 {
    @apply h-6 w-6;
  }

  .size-5x5 {
    @apply h-5 w-5;
  }

  .size-4x4 {
    @apply h-4 w-4;
  }

  .size-3x3-half {
    @apply h-3.5 w-3.5;
  }

  .size-3x3 {
    @apply h-3 w-3;
  }

  .size-2x2 {
    @apply h-2 w-2;
  }
}

@layer utilities {
  /* For Webkit-based browsers (Chrome, Safari and Opera) */
  .scrollbar-hide::-webkit-scrollbar {
    display: none;
  }

  /* For IE, Edge and Firefox */
  .scrollbar-hide {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .input-section-hide *:is(input, select, option, textarea) {
    visibility: hidden;
  }
}

.accordion-button[aria-expanded="true"] svg {
  transform: rotate(180deg);
}

.zest-input-error {
  @apply text-red-600;
}
input[aria-invalid="true"],
select[aria-invalid="true"],
textarea[aria-invalid="true"] {
  @apply border border-red-600;
  @apply bg-red-50;
}
