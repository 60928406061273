@font-face {
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 400;
  src: url(https://resources.cfse.biz/uploads/design/2024/8/Marketplace/worksans-regular.woff2);
  font-display: swap;
}
@font-face {
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 500;
  src: url(https://resources.cfse.biz/uploads/design/2024/8/Marketplace/worksans-medium.woff2);
  font-display: swap;
}
@font-face {
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 700;
  src: url(https://resources.cfse.biz/uploads/design/2024/8/Marketplace/worksans-bold.woff2);
  font-display: swap;
}
